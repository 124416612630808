<template>

    <div class="h-full w-full relative">
    
        <loader :loading="loading" />

        <div v-if="!opened && essentials" class="flex h-full overflow-hidden">

            <div v-if="focusnew" class="w-3/5 flex flex-col mr-8">

                <h3 class="box-title">{{ focusnew.date | moment('DD/MM/YYYY') }}</h3>

                <div class="mt-4 flex-1 min-h-0">

                    <div class="box p-0 max-h-full h-full flex flex-col px-2 ">

                        <div v-show="focusnew.image" class="h-64 w-full rounded-lg bg-cover mt-2 shadow-xl relative"
                            :style="'background-image: url(' + imageRoute + focusnew.image + ')'">

                            <router-link :to="{ name: 'TheEssential', params: { id: focusnew.id } }">
                                <div class="show-more cursor-pointer"></div>
                            </router-link>

                            <!-- <div class="show-more cursor-pointer" @click="open(focusnew)"></div> -->

                        </div>

                        <div class="mt-4 px-6 flex-1 min-h-0 flex flex-col">

                            <div class="h-auto flex flex-row justify-start items-center w-full">
                                <h4 class="text-2xl font-medium leading-normal truncate-2 capitalize">{{ focusnew.title }}</h4>
                                <span class="text-lg text-font-gray ml-auto">{{focusnew.date | moment('DD / MM / YYYY')}}</span>
                            </div>


                            <div class="mt-3 flex-1 h-full min-h-0 text-lg text-font-gray leading-normal overflow-auto">
                                <div class="" v-html="focusnew.body"></div>
                            </div>

                        </div>

                        <div class="px-6 py-2 flex justify-end items-center">

                            <i v-if="focusnew.link !== ''" class="mdi mdi-open-in-new text-font-gray
                                cursor-pointer text-lg mr-2" @click="goexternal(focusnew.link)"></i>
                                
                            <i v-if="focusnew.file !== ''" class="mdi text-lg cursor-pointer"
                                :class="extension(focusnew)" @click="openFile(focusnew.file, 'file')"></i>

                            <span class="ml-2 text-lg text-font-gray">{{ focusnew.likes.length }}</span>

                            <div class="ml-1 h-6 w-6 flex flex-col justify-center items-center cursor-pointer mr-1" @click="dolike(focusnew.id)">
                                <i class="mdi mdi-thumb-up-outline text-lg" :class="melike(focusnew.likes)"></i>
                            </div>

                        </div>
                        
                    </div>

                </div>

            </div>

            <div class="w-2/5 flex flex-col ml-8 overflow-hidden">

                <div class="flex items-center h-10">
                    <div class="box-title mr-6">Latest</div>
                    <div class="h-full flex flex-1 min-w-0 ml-auto mr-14">
                        <input type="text" placeholder="Search" v-model="searchInput" class="w-full
                            h-full rounded bg-box-40 flex flex-row justify-start items-center px-2" />
                    </div>
                </div>

                <div class="flex flex-1 min-h-0 overflow-hidden relative">

                    <div class="scroll-gradient"></div>

                    <div class="h-full overflow-auto pr-12">

                        <template v-for="(el, index) in search(essentials)" >

                            <div v-if="el !== focusnew" :key="index" class="mt-3 first:mt-0
                                relative box px-4 py-4 group flex items-stretch hover:bg-warning-30 cursor-pointer" @click="focusnew = el">
                                <!-- <div class="show-more cursor-pointer" @click="open(el)"></div> -->
                                <div v-show="el.image" class="h-28 w-28 mb-6">
                                    <img class="mb-4 w-full h-full rounded-lg object-cover bg-center shadow-xl" :src="imageRoute + el.image"/>
                                </div>
                                <div class="ml-5 flex-1 min-w-0 flex flex-col">
                                    <div class="h-6 flex flex-row justify-end items-center px-2">
                                        <span class="text-xs text-right text-font-gray group-hover:text-warning">{{ el.date | moment('DD/MM/YYYY')}}</span>
                                    </div>
                                    <div>
                                        <div class="mt-1 mr-4">
                                            <h3 class="text-lg font-bold truncate">{{ el.title }}</h3>
                                        </div>
                                    </div>
                                    <div class="mt-3 flex-1 min-h-0 mr-4">
                                        <p class="text-sm text-font-gray group-hover:text-font-light text-justify font-medium truncate-2" v-html="el.body"></p>
                                    </div>
                                    <div class="mt-2 flex justify-end items-center px-2">
                                         <i v-if="el.link !== ''" class="mdi mdi-open-in-new text-font-gray
                                            cursor-pointer text-lg mr-2" @click="goexternal(el.link)"></i>
                                        <i v-if="el.file !== ''" class="mdi text-lg cursor-pointer"
                                            :class="extension(el)" @click="openFile(el.file, 'file')"></i>
                                        <span class="ml-2 text-sm text-font-light leading-none">{{ el.likes.length }}</span>
                                        <div class="h-6 w-6 flex flex-col justify-center items-center cursor-pointer mr-1">
                                            <i class="mdi mdi-thumb-up-outline text-sm group-hover:text-warning" :class="melike(el.likes)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="el !== focusnew" :key="index" class="mt-3 first:mt-0
                                relative box px-4 py-4 group flex items-stretch hover:bg-warning-30 cursor-pointer" @click="focusnew = el">

                                <!-- <div class="show-more cursor-pointer" @click="open(el)"></div> -->
                                <div v-show="el.image" class="h-28 w-28 mb-6">
                                    <img class="mb-4 w-full h-full rounded-lg object-cover bg-center shadow-xl" :src="imageRoute + el.image"/>
                                </div>
                                <div class="ml-5 flex-1 min-w-0 flex flex-col">
                                    <div class="h-6 flex flex-row justify-end items-center px-2">
                                        <span class="text-xs text-right text-font-gray group-hover:text-warning">{{ el.date | moment('DD/MM/YYYY')}}</span>
                                    </div>
                                    <router-link :to="{ name: 'TheEssential', params: { id: el.id }}">
                                        <div class="mt-1 mr-4">
                                            <h3 class="text-lg font-bold truncate">{{ el.title }}</h3>
                                        </div>
                                    </router-link>
                                    <div class="mt-3 flex-1 min-h-0 mr-4">
                                        <p class="text-sm text-font-gray group-hover:text-font-light text-justify font-medium truncate-2" v-html="el.body"></p>
                                    </div>
                                    <div class="mt-2 flex justify-end items-center px-2">
                                        <i v-if="el.link !== ''" class="mdi mdi-open-in-new text-font-gray
                                            cursor-pointer text-lg mr-2" @click="goexternal(el.link)"></i>
                                        <i v-if="el.file !== ''" class="mdi text-lg cursor-pointer"
                                           :class="extension(el)" @click="openFile(el.file, 'file')"></i>
                                        <span class="ml-2 text-sm text-font-light leading-none">{{ el.likes.length }}</span>
                                        <div class=" h-6 w-6 flex flex-col justify-center items-center cursor-pointer mr-1" @click="dolike(el.id)">
                                            <i class="mdi mdi-thumb-up-outline text-sm group-hover:text-warning" :class="melike(el.likes)"></i>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </template>

                    </div>

                </div>

            </div>

        </div>

        <div v-else class="h-full w-full flex flex-col mr-12">

            <router-link v-if="!paramId" :to="{ name: 'TheEssential', params: { id: null }}">
                <div class="px-6 flex justify-end">
                    <div class="flex items-center cursor-pointer" @click="open(null)">
                        <div class="h-6 w-6 rounded-full flex justify-center items-center bg-box">
                            <i class="mdi mdi-chevron-left text-aux"></i>
                        </div>
                            <div class="mt-1 mr-4">
                                <span class="text-sm text-aux ml-2">Go back</span>
                            </div>
                    </div>
                </div>
            </router-link>

            <div v-if="selected" class="mt-3 flex-1 min-h-0 box p-4" >

                <div class="h-full px-8 mr-16 flex flex-row">

                    <div class="h-full w-1/2 flex flex-col px-2">
                        <div v-show="selected.image" class="h-full shadow-xl">
                            <img class="w-full h-full  object-cover rounded-lg" :src="imageRoute + selected.image"/>
                        </div>
                    </div>

                    <div class="h-full w-1/2 flex flex-col px-2">

                        <div class="flex-1 min-h-0 overflow-auto">
                            <div class="mt-6 mb-6">
                                <h3 class="text-4xl font-medium leading-normal truncate-2">{{ selected.title }}</h3>
                            </div>

                            <div class="text-font-gray leading-relaxed" v-html="selected.body"></div>

                        </div>

                        <div class="px-6 py-4 flex justify-end items-center">
                             <i v-if="selected.link !== ''" class="mdi mdi-open-in-new text-font-gray
                                cursor-pointer text-lg mr-2" @click="goexternal(selected.link)"></i>
                            <i v-if="selected.file !== ''" class="mdi text-lg cursor-pointer"
                                :class="extension(selected)" @click="openFile(selected.file, 'file')"></i>
                            <div class="h-8 flex items-center">
                                <span class="ml-2 text-lg">{{ selected.likes.length }}</span>
                                <div class=" h-8 w-8 flex flex-col justify-center items-center cursor-pointer mr-1" @click="dolike(selected.id)">
                                    <i class="mdi mdi-thumb-up-outline text-xl text-warning" :class="melike(selected.likes)"></i>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import {state} from '@/store';

    export default {
        data() {
            return {
                stringFilter: '',
                stringModel: '',
                opened: false,
                selected: null,
                essentials: null,
                searchInput:'',
                idParams: false,
                loading: false,
                focusnew: false,
                allEssentials: false,
                paramId: null
            }
        },
        methods:{
            setStringFilter(stringModel) {
                if (stringModel) { this.stringFilter = stringModel }
                else {
                    this.stringFilter = ''
                    this.stringModel = ''
                }
            },
            open(selected) {
                if (this.opened) {
                    this.selected = null
                    this.opened = !this.opened
                    this.paramId = null
                }
                else {
                    this.selected = selected
                    this.opened = !this.opened
                }
            },
            selectEssential(id) {
                if (id) {
                    this.selected = this.allEssentials.find(item => String(item.id) === String(id));
                    this.opened = !!this.selected;
                } else {
                    this.selected = null;
                    this.opened = false;
                }
            },
            async load() {

                try {

                    this.loading = true

                    if(this.paramId){

                        const resp = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'get?resource=Essential&id='+this.paramId, {params: {roleTypeId:state.globalParams.roleTypeId, gui_role_specific_id:state.globalParams.gui_role_specific_id}});
                        
                        this.opened = true;
                        this.selected = resp.data
                        this.essentials = true

                    } else {

                        const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Essential&sorts[date]=DESC', {params: state.globalParams});

                        this.allEssentials = data;
                        this.essentials = data.filter((essential) => essential.active == 1);
                        this.focusnew = data[0];

                        this.selectEssential(this.paramId);

                    }

                } catch (error) {

                } finally {

                    this.loading = false;

                }

            },
            search(data){ return this.$search(data, this.searchInput) },
            essentialsFiltered() {
                if (this.idParams && this.essentials) {
                    for (let index = 0; index < this.essentials.length; index++) {
                        if(this.essentials[index].id === this.idParams){
                            this.open(this.essentials[index])
                        }
                    }
                }
            },
            dolike(id) {
                this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Essential&id='+id)
                    .then(response => {
                        this.load();
                    })
            },
            melike(essential) {
                return this.$meLike(essential)
            },
            extension(element) {
                let result = ''
                result += this.$typeNotification(element.notification_type)
                result += this.$iconByExtension(element.file)
                return result
            },
            openFile(file, type) { this.$openFile(file, type) },
            goexternal(link) { window.open(encodeURI(link), '_blank') },
            selectIdParam() {
                for (let index = 0; index < this.essentials.length; index++) {
                    if (this.essentials[index].id === this.idParams) {
                        this.selected = this.essentials[index]
                    }
                }
            }
        },
        computed: {
            imageRoute() { return this.$imageRoute },
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
            user() { return state.user },
            essentialId() {
                return this.$route.params.id;
            }
        },
        watch: {
            essentialId(id) {
                if (id) {
                    this.selectEssential(id);
                }
            },
            essentials() { this.essentialsFiltered() },
            paramId(){
                this.load();
            }
        },
        mounted() {
            this.paramId = this.$route.params.id;
            this.load();

            setInterval(() => {
                this.load();
            }, state.refreshPersonalDashboard);

        }
    }
</script>